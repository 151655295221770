.customSection__jumbotron{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url(./bgg.png);
  background-repeat: initial;
 background-size: cover;
 margin-bottom: 100px;
}
.customSection__h1{
  font-size: 71px;
  margin-bottom: 5px;
  /* color: rgb(0, 123, 252); */
  color: #fff;
  line-height: 71px;
  font-weight: 400;
}
.customSection__h2{
  font-size: 35px;
  margin-bottom: 5px;
  line-height: 60px;
  /* color: rgb(13, 30, 103); */
  color: #efefee;
  font-weight: 400;
}
.customSection__p{
  color: rgb(94, 114, 144);
  font-size: 20px;
}
.customSection__button{
  padding: 10px 20px ;
  border-radius: 5px;
  border: 1px solid #efefef;
  background-color: #0a68f6;
  color: #ffff;
  transition: all ease 5ms;
}
.customSection__button:hover{
  background-color:#0d1e67;
  color: #fff !important;
}
/* this animation section  */
.customSection__Conatiner{
  animation: customAnimation 3s ease forwards; /* Apply the animation */
}
@keyframes customAnimation {
  0% {
    transform: translateX(-100%); /* Start off-screen to the left */
    opacity: 0; /* Start with opacity 0 */
  }
  100% {
    transform: translateX(0); /* Move to the original position */
    opacity: 1; /* Fade in with full opacity */
  }
}

@keyframes customImageAnimation {
  0% {
    transform: translateX(100%); /* Start off-screen to the left */
    opacity: 0; /* Start with opacity 0 */
  }
  100% {
    transform: translateX(0); /* Move to the original position */
    opacity: 1; /* Fade in with full opacity */
  }
}

.customImage_section {
  animation-delay: 1s;
  animation: customImageAnimation 3s ease forwards; /* Apply the animation */
}

@media (max-width: 768px) {
  .customSection__jumbotron{
    height: auto;
  }
  .customSection__h1 {
    font-size: 1.5rem; /* Adjust as needed */
    line-height: 30px;
  }

  .customSection__h2 {
    font-size: 1.25rem; /* Adjust as needed */
  }

  .customSection__p {
    font-size: 0.9rem; /* Adjust as needed */
  }
}
