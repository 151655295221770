.iq-process-step .iq-before-img { position: absolute; right: -20%; top: 15%; }
.text-left .iq-process-step  .iq-before-img { right: 15%; }
.text-right .iq-process-step  .iq-before-img { left: 10%; right: auto; }
.iq-before-img { position: absolute; right: -20%; top: 15%; }
.iq-process-step-style-2 .iq-process-step { position: relative; display: block; }
.iq-process-step-style-2 .iq-process-step .iq-step-content { margin-bottom: 30px; text-align: center; background: #ffffff; height: 110px; width: 110px; -webkit-border-radius: 110px; -moz-border-radius: 110px; border-radius: 110px; position: relative; top: 0; display: inline-block; -webkit-box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1); -moz-box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1); box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1); transition: all 0.5s ease-in-out; -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; }
.iq-process-step-style-2 .iq-process-step:hover .iq-step-content { top: -8px; }
.iq-process-step-style-2 .iq-process-step .iq-step-content i { font-size: 50px; line-height: 110px; color: #007bfc; }
.iq-process-step-style-2 .iq-process-step .step-number { font-size: 18px; background: #007bfc; -webkit-border-radius: 100px; -moz-border-radius: 100px; border-radius: 100px; color: #ffffff; width: 36px; height: 36px; line-height: 36px; text-align: center; position: absolute; top: 0; right: 0; }
.iq-process-step-style-2 .iq-process-step h5 span { height: 6px; width: 6px; background: #007bfc; -webkit-border-radius: 100px; -moz-border-radius: 100px; border-radius: 100px; display: inline-block; margin-left: 6px; }
.iq-process-step-style-2 .iq-process-step .iq-step-text-area .iq-step-title { margin-bottom: 10px; }
.iq-process-step-style-2 .iq-process-step .iq-step-text-area .iq-step-desc { display: block; }


img.shape { position: absolute; left: 71%; top: 13%; width: 30%; }
img.shape-left { position: absolute; right: 88%; top: 5%; width: 30%; }
img.shape-right { position: absolute; left: 86%; top: 5%; width: 30%; }




@media (max-width: 767px) {
  .iq-process .iq-before-img {
    display: none !important;

  }

  .iq-process .iq-step-title {
    margin-top: 15px;
  }
  .iq-process {
    margin-left: 15px;
    margin-right: 15px;
  }
}





.Ibox-container {
  padding: 10px;
  margin: 10px;
  width: 250px;
  height: auto;
  text-align: left;
  position: relative;
  overflow: hidden;
  transition: all ease 0.3s;
}

/* @media only screen and (max-width: 767px) {
  .Ibox-container{
    height:auto;
  }
} */

.Ibox-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  /* background-color: #007bff;  */
  transform: scaleX(0);
  transform-origin: bottom left;
  transition: transform 0.3s;
}

.Ibox-container:hover {
  box-shadow: 2px 10px 10px 2px rgba(0, 0, 0, 0.1);
}

.Ibox-container:hover::after {
  transform: scaleX(1);
}

.Ibox-title {
  font-size: 1.5em;
  color: #333;
}

.Ibox-subtitle {
  font-size: 1em;
  color: #666;
}
.web-hosting-container {
  margin-top: 60px;
}


 .row2 {
  display: flex;
}

.small-column {
  flex: 1;
  padding-right: 10px;
}

.big-column {
  flex: 2;
  padding-left: 10px;
}

.divider {
  width: 1px;
  background-color: #000;
  margin: 15px 10px ;
  /* height: 70px;
  margin-top: 10px; */
}

@media (max-width: 768px) {
  .row2 {
    flex-direction: column;
    align-items: stretch;

  }

  .divider {
    display: none; /* Hide the divider in mobile view */
  }

  .small-column,
  .big-column {
    flex-basis: auto; /* Reset width for mobile view */
  }

/* .Pcolumn {
  margin: 0;
  margin-top: 20px;
} */


}
/* //////////////////////////// */
.side-navigation-card {
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); /* Adjust shadow as needed */
}

.side-navigation-card .list-group-item {
  cursor: pointer;
}

.side-navigation-card .list-group-item.active {
  background-color: #007bff;
  color: #fff;
}


@media (max-width: 767px) {
  .single-service {
    height: 500px; /* Adjusted height for mobile devices */
  }
}





.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensures the container takes at least the full height of the viewport */
}

.content {
  flex: 1; /* Allows this section to take up all available space */
}



/* Default desktop styles */
.career-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 50px 10%;
  justify-content: center;
}

.career-block {
  background-color: white;
  width: calc(25% - 20px); /* Four items per row */
  margin: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.career-block-admin {
  background-color: white;
  width: calc(25% - 20px);
  height: auto;
  margin: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  color: black;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 1px solid #e0e0e0;
  /* // cursor: 'pointer; */
  position: relative;
  /* // Added position: relative */

}
.career-button-container {
  width: 100%;
  /* text-align: center; */
}

.career-button {
  margin-top: 20px;
  padding: 10px 20px;
  border: 1px solid black;
  /* border-radius: 5px; */
  background-color: white;
  color: black;
  cursor: pointer;
  text-align: center;
}

.career-details {
  margin: 50px 200px 50px 170px;
  /* padding: 20px; */
  /* max-width: 800px; */


}
.footer_career {
  margin-top: auto; /* Pushes the footer to the bottom of the container */
}

.career-details-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.CareerRegcontainerUser {
  margin: 50px 200px 50px 200px;
}

.career-summary {
  margin-top: 20px;
}

.OpeningcontainerUser{
  margin: 50px 200px 50px 200px;
}


.deleteButtonStyle {
  position: absolute;

  top: 75px;
  right: 15px;
  margin: 0;
  color: red;
  cursor: pointer;
  width:16px;
  height:16px;
}

.viewButtonStyle  {
  position: absolute;
  top: 20px;
  right: 15px;
  margin: 0;
  color: green;
  cursor: pointer;
   width:18px;
   height:18px;
}

.EditButtonStyle {
  position: absolute;
  top: 45px;
  right: 13px;
  margin: 0;
  color: green;
  cursor: pointer;
  width:18px;
  height:18px;
}




/* Mobile styles */
@media (max-width: 768px) {
  .career-container {
    margin: 30px 20px;
  }

  .viewButtonStyle  {
    display: none;
  }
  .EditButtonStyle {
    display: none;
  }
  .deleteButtonStyle {
    display: none;
  }


  .career-block {
    width: calc(50% - 20px); /* Two items per row on tablets */
  }

  .career-block-admin {
    width: calc(50% - 20px); /* Two items per row on tablets */
  }

  .CareerRegcontainerUser {
    margin: 20px 10px 50px 10px;
  }

  .OpeningcontainerUser{
    margin: 20px 10px 50px 10px;
  }
  .career-details {
    margin: 30px 10px 50px 20px;
  }

  .career-details-row {
    flex-direction: column;
  }

  .career-button-container {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .career-block {
    width: 100%; /* One item per row on phones */
    margin: 10px 0;
  }

  .career-block-admin {
    width: 100%; /* One item per row on phones */
    margin: 10px 0;
  }

}



/* this is for career */
/* this is for career */
/* this is for career */
/* this is for career */
/* this is for career */


.career-block {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.job-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.job-subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
}

.job-meta {
  font-size: 14px;
  color: #888;
  margin-bottom: 15px;
}

.job-action {
  text-align: center;
}

.apply-button {
  background-color: #007bff !important;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out !important;
}

.apply-button:hover {
  background-color: rgb(13, 30, 103) !important;
}

.icon123 {
  margin-left: 5px;
  color: #fff;
}
.icon12345 {
  margin-right: 5px;
  color: #007bff;
}

.career-details {
  /* padding: 20px; */
  border-radius: 5px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  margin-bottom: 20px;
}

.career-details h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.job-details {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.job-benefits{
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.job-description {
  flex-wrap: wrap;
  margin-bottom: 20px;
  
}


.job-info, .job-description {
  margin-bottom: 10px;
}

.label123 {
  font-weight: bold;
  margin-right: 5px;
}

.career-button-container {
  /* text-align: center; */
  margin-bottom: 20px;
}

.career-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.career-button:hover {
  background-color: rgb(13, 30, 103);
}

.job-benefits {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 15px;
}

.nextLineCss{
  white-space: nowrap;
  display: block;
}

/* Form Styles */
/* Form Styles */
.CareerRegcontainerUser  {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0. 1);
}

.row12345 {
  margin-bottom: 20px;
}

.form-label12345 {
  font-weight: bold;
  margin-bottom: 5px;
}

.required {
  color: red;
}

.inputStyle {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 10px;
  transition: border-color 0.3s;
}

.inputStyle:focus {
  border-color: #80bdff;
  outline: none;
}

.errorStyle {
  color: red;
  font-size: 0.875em;
  margin-top: 5px;
}

.buttonStyle {
  margin-top: 20px;
  background-color: #006fef;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.buttonStyle:hover {
  background-color: #0056b3;
}

.buttonStyle:disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}

/* .career-block {
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.career-block:hover {
  transform: scale(0.98); 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); 
} */

/* career */
/* career */

/* career */
/* career */
/* career */
/* career */
/* career */
/* career */
/* career */
/* career */


.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-bar123 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-bottom: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.step-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #ffffff;
  font-size: 18px;
}

.step.completed .step-icon {
  background-color: #007bff;
}

.step-label {
  margin-top: 8px;
  color: rgb(13, 30, 103);
  font-weight: 500;
}

.step-line {
  position: absolute;
  top: 20px;
  left: 50%;
  width: calc(100% + 20px);
  height: 2px;
  background-color: #e0e0e0;
  z-index: -1;
}

.step.completed .step-line {
  background-color: #007bff;
}

.buttons {
  display: flex;
  gap: 10px;
}

