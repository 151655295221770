.DpSidebarBtn.active {
   background-color: #242424;
   color: #ffff;
  }

  .accordion > .card > .card-header{
   margin-bottom: 0 !important;
  }

  @media (max-width: 767px) {
   .DropDown_Container {
     display: none;
   }
 }
 
 /* For tablet devices (768px to 991px) */
 @media (min-width: 768px) and (max-width: 991px) {
   .DropDown_Container {
     display: none;
   }
 }

 .accordianMobile__View{
   display: none;
 }

 @media (max-width: 767px) {
   .accordianMobile__View {
     display: block;
   }
 }
 
 /* Media query for tablet devices (768px to 991px) */
 @media (min-width: 768px) and (max-width: 991px) {
   .accordianMobile__View {
     display: block;
   }
 }